import React, { useState, useEffect, useRef } from 'react';
import '../css/ListComponent.css';
import {handleImport} from '../utilities/exactonline';
import {getCookie} from '../utilities/utils'; 
import { clearExistingScheduledImports, } from '../utilities/import';
import StatusComponent from './StatusComponent';

import FilterComponent from './FilterComponent';
import ConfirmationComponent from './ConfirmationComponent';
import SchedulerComponent from './SchedulerComponent';
import EditComponent from './EditComponent';

// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV, faSortDown, faSortUp, faFilter, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations  } from './TranslationContext';

// Add the icons you will use to the library
library.add(faEllipsisV, faSortDown, faSortUp, faFilter);

const ListComponent = ({data, reloadImport, importType, updateImport, deleteImport, handleImport}) => {
  const [entity, setEntity] = useState([]);
  const [firstScheduledImports, setFirstScheduled] = useState([]);
  const [statusUpdates, setStatusUpdates] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showScheduler, setShowScheduler] = useState(false);
  const { language, getTranslation } = useTranslations();

  // Filter variables
  const [sortYear, setSortYear] = useState(false);
  const [sortCase, setSortCase] = useState(false);
  const [sortAdministration, setSortAdministration] = useState(false);
  const [sortName, setSortName] = useState(false);
  const [sortBudgetScenario, setBudgetScenario] = useState(false);
  const [sortStamp, setSortStamp] = useState(false);
  const [sortCreated, setSortCreated] = useState(false);
  const [showFilterInput, setShowFilterInput] = useState(false);
  const [filterString, setFilterString] = useState(false);

  // Transform importexact array for the table list 
  useEffect(() => {  
    fetchRowScheduledImport(data);
    transformImportExact(data);
  }, [data, sortYear, sortCase, sortAdministration, sortName, sortBudgetScenario, sortStamp, sortCreated, showFilterInput, filterString]);

  useEffect(() => {  
  },[statusUpdates]);
  
  // Updates status messages array
  const updateStatus = (message) => {
    setStatusUpdates(message);
  };

  // handles import exact to list array changes like "entiteit to display name" and "filter options"
  async function transformImportExact(importExact){
    
    var transformedData = await Promise.all(importExact.map(async (item) => {
      const index = item.entiteit.indexOf('_');
      const entiteitDesc = await getCaseNameById(index === -1 ? item.entiteit : item.entiteit.substring(0, index));
      
      return {
        ...item,
        entiteitDesc,
      };
    }));
    if(sortYear){
      transformedData = await sortByYear(transformedData, sortYear);
    }
    else if(sortCase){
      transformedData = await sortByString(transformedData, "entiteitDesc", sortCase);
    }
    else if(sortAdministration){
      transformedData = await sortByString(transformedData, "office_description", sortAdministration);
    }
    else if(sortName){
      transformedData = await sortByString(transformedData, "name", sortName);
    }
    else if(sortBudgetScenario){
      transformedData = await sortByString(transformedData, "budget_scenario", sortBudgetScenario);
    }
    else if(sortStamp){
      transformedData = await sortByStamp(transformedData, sortStamp);
    }
    else if(sortCreated){
      transformedData = await sortByCreated(transformedData, sortCreated);
    }
    else if(filterString){
      transformedData = await sortByFilterString(transformedData, showFilterInput, filterString);
    }
    else{
      transformedData = await sortByYear(transformedData, "desc");
    }

    setEntity(transformedData);
    return transformedData;
  }

  // Get case name by id
  async function getCaseNameById(id){
    const response = await fetch('https://api2.factview.nl/case/get?id='+id, {
      method: 'GET',
      headers: {
        'authorization': 'Bearer '+ getCookie("factview_access"),
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
    })
    if (response.ok) {
      const responseData = await response.json();
      return responseData.name;
    }
  }
  
  // Handles list open/close events
  async function handleListEditor(event){

    var editorBtn = event.currentTarget;
    var editorContainer = editorBtn.querySelector(".editor-dropdown");
    // Named function for handling clicks outside the editor
    function handleClickOutside(event) {
      // Check if the clicked element is not the editor button or a descendant of it
      if (editorBtn && !editorBtn.contains(event.target)) {
          // Close the editor
          editorContainer.classList.add("hidden");
          // Remove the click event listener after closing the editor
          document.removeEventListener('click', handleClickOutside);
      }
    }

  // Add the click event listener to the document
  document.addEventListener('click', handleClickOutside);
  
    if(editorContainer.classList.contains("hidden")){
      closeAllListEditors();
      editorContainer.classList.remove("hidden");
    }
    else{
      editorContainer.classList.add("hidden");
    }
  }
  // Close all list editors
  async function closeAllListEditors(){
    var editors = document.querySelector(".list-content").querySelectorAll(".editor-dropdown");
    for(const editor of editors){
      if(!editor.classList.contains("hidden")){
        editor.classList.add("hidden");
      }
    }
  } 

  // Handles schedule import onClick event to open scheduler
  async function scheduleImportClicked(id){
    setShowScheduler(id);
  }

  // Handles open list import editor option
  async function listEditClicked(id){
    setShowEdit(id);
  }

  // Handles the onClick delete event in the list
  async function listDeleteClicked(id){
    setShowConfirm(id);
  }

  // Close all editors/inputs in the list
  async function closeOptions(){
    setShowConfirm(false);
    setShowEdit(false);
    setShowScheduler(false);
  }

  async function handleDelete(id){
    await deleteImport(id); 
    await reloadImport();
  }
  
  // Handles the sort ASC/DESC onClick event 
  function handleSortClicked(type){
    resetListSorting(type);

    if(type == "entiteit"){
      setSortCase((!sortCase)?"asc":(sortCase=="asc")?"desc":false);
    }
    if(type == "administration"){
      setSortAdministration((!sortAdministration)?"asc":(sortAdministration=="asc")?"desc":false);
    }
    else if(type == "year"){
      setSortYear((!sortYear)?"asc":(sortYear=="asc")?"desc":false);
    }
    else if(type == "budget_scenario"){
      setBudgetScenario((!sortBudgetScenario)?"asc":(sortBudgetScenario=="asc")?"desc":false);
    }
    else if(type == "name"){
      setSortName((!sortName)?"asc":(sortName=="asc")?"desc":false);
    }
    else if(type == "stamp"){
      setSortStamp((!sortStamp)?"asc":(sortStamp=="asc")?"desc":false);
    }
    else if(type == "created"){
      setSortCreated((!sortCreated)?"asc":(sortCreated=="asc")?"desc":false);
    }
  }

  // Reset list sorting by type
  function resetListSorting(type){
    if(type != "entiteit" && sortCase){
      setSortCase(false);
    }
    if(type != "year" && sortYear){
      setSortYear(false);
    }
    if(type != "budget_scenario" && sortBudgetScenario){
      setBudgetScenario(false);
    }
    if(type != "name" && sortName){
      setSortName(false);
    }
    if(type != "stamp" && sortStamp){
      setSortStamp(false);
    }
    if(type != "created" && sortCreated){
      setSortCreated(false);
    }
    if(type != "administration" && sortAdministration){
      setSortAdministration(false);
    }
  }

  // Function to sort the array in ascending or descending order based on the year
  function sortByYear(arr, order = 'desc') {
    var sortedArr = arr.sort((a, b) => {
      if (order === 'asc') {
        return parseInt(a.year) - parseInt(b.year);
      } else if (order === 'desc') {
        return parseInt(b.year) - parseInt(a.year);
      }
    });

    return sortedArr;
  }

  // Function to sort array by timestamp
  function sortByStamp(arr, order = 'asc') {
    return arr.sort((a, b) => {
      const dateA = new Date(a.stamp);
      const dateB = new Date(b.stamp);

      if (order === 'asc') {
        return dateB - dateA;
      }

      return dateA - dateB;
    });
  }
 // Function to sort array by date
 function sortByCreated(arr, order = 'asc') {
  return arr.sort((a, b) => {
    const dateA = new Date(a.date_created);
    const dateB = new Date(b.date_created);

    if (order === 'asc') {
      return dateB - dateA;
    }

    return dateA - dateB;
  });
}
  // Function to handle filtering by string ASC/DESC
  function sortByString(arr, type, order = 'asc') {
    return arr.sort((a, b) => {
      if (order === 'asc') {
        return a[type].localeCompare(b[type]);
      } 
      else if (order === 'desc') {
        return b[type].localeCompare(a[type]);
      }
    });
  }

  // Function to handle the filter input onChange event
  async function sortByFilterString(arr, type, filterString) {
    var sortedArr = [];
    for(const el of arr){
      if(el[type].toLowerCase().includes(filterString.toLowerCase())){
        sortedArr.push(el);
      }
    }

    var sortedArr = sortedArr.sort((a, b) => {
      return a[type].localeCompare(b[type]);
    });

    return sortedArr;
  }

  // Handles the onClick filter event
  function handleFilterClicked(type){
    resetListSorting(false);
    setShowFilterInput(type);
  }
  async function fetchRowScheduledImport(imports){
    var uniqueEntity = [];
    var scheduledImps = [];
    for(const imp of imports){
      var exists = false;
      for(const entity of uniqueEntity){
        if(entity == imp.entiteit){
          exists = true;
        }
      }
      if(!exists){
        uniqueEntity.push(imp.entiteit);
        const response = await fetch('/scheduler/getScheduledImportsByEntity?entity='+imp.entiteit);

        if (response.ok) {
          const responseData = await response.json();
          if(responseData){       
            
            for(const data of responseData){
              scheduledImps.push(data);
            }
          }
        }
      }
    }
    getFirstScheduled(imports, scheduledImps);
  }
  async function getFirstScheduled(imps, scheduledImps){
    var scheduledTime = "-";
    var firstScheduled = []; 
    for(const imp of imps){
      var exists = false;
      for(const scheduled of scheduledImps)
      {
       
        if(scheduled.entiteit == imp.entiteit && scheduled.budget_scenario == imp.budget_scenario && scheduled.year == imp.year && scheduled.office == imp.office){
          exists = true;
          if(firstScheduled[imp.id] && new Date(firstScheduled[imp.id]) < new Date(scheduled.scheduled_time) || new Date() > new Date(scheduled.scheduled_time) ){
       
          }
          else{
            firstScheduled[imp.id] = new Date(scheduled.scheduled_time);
          }
        }
      }
      if(!exists){firstScheduled[imp.id] = "-"; }
    }
    setFirstScheduled(firstScheduled);
  }
  return (
    <div className="list-content">
      <table border="1">
        <tbody>
            <tr className="list-header">
              <td className="list-case">
                <div className="list-flex">
                  <div className={(showFilterInput == "entiteit")?"case-el no-wrap":"case-el no-wrap hover"}   onClick={(showFilterInput == "entiteit")?null:()=>handleSortClicked("entiteit")}>
                    {getTranslation("import_Case")}
                  {(showFilterInput == "entiteit") ? 
                    <div className="filter-container">
                      <FilterComponent data={entity} type={"entiteit"} setFilterString={setFilterString} setShowFilterInput={setShowFilterInput}/>
                    </div>: null} 
                  </div>
                  <div className="list-sort">
                    {(!sortCase)?null:<FontAwesomeIcon className="sort-icon" icon={(sortCase == "asc")?faSortUp:faSortDown}/>}
                    {<FontAwesomeIcon className="filter-icon hover" onClick={()=>handleFilterClicked("entiteit")} icon={faFilter}/>}
                  </div>
                </div>
              </td>
             {(importType == "exactonline") ? <td className="list-administration">
                <div className="list-flex">
                  <div className={(showFilterInput == "administration")?"administration-el no-wrap":"administration-el no-wrap hover"}   onClick={(showFilterInput == "administration")?null:()=>handleSortClicked("administration")}>
                    {getTranslation("import_ExactAdministration")}
                  {(showFilterInput == "administration") ? 
                    <div className="filter-container">
                      <FilterComponent data={entity} type={"administration"} setFilterString={setFilterString} setShowFilterInput={setShowFilterInput}/>
                    </div>: null} 
                  </div>
                  <div className="list-sort">
                    {(!sortAdministration)?null:<FontAwesomeIcon className="sort-icon" icon={(sortAdministration == "asc")?faSortUp:faSortDown}/>}
                    {<FontAwesomeIcon className="filter-icon hover" onClick={()=>handleFilterClicked("administration")} icon={faFilter}/>}
                  </div>
                </div>
              </td> : null}
              <td className="list-year">
                <div className="list-flex">
                  <div className={(showFilterInput == "year")?"year-el no-wrap":"year-el no-wrap hover"}   onClick={(showFilterInput == "year")?null:()=>handleSortClicked("year")}>
                    {getTranslation("import_Year")} 
                    {(showFilterInput == "year") ? <div className="filter-container">
                      <FilterComponent data={entity} type={"year"} setFilterString={setFilterString} setShowFilterInput={setShowFilterInput}/>
                    </div>: null} 
                  </div>
                  <div className="list-sort">
                    {(!sortYear)?null:<FontAwesomeIcon className="sort-icon" icon={(sortYear == "asc")?faSortUp:faSortDown}/>}
                    {<FontAwesomeIcon className="filter-icon hover"  onClick={()=>handleFilterClicked("year")} icon={faFilter}/>}
                  </div>
                </div>
              </td>
              <td className="list-budgetscenario" >
                <div className="list-flex">
                  <div className={(showFilterInput == "budget_scenario")?"budgetscenario-el no-wrap":"budgetscenario-el no-wrap hover"}   onClick={(showFilterInput == "budget_scenario")?null:()=>handleSortClicked("budget_scenario")}>
                    {getTranslation("import_BudgetScenario")}  
                    {(showFilterInput == "budget_scenario") ? <div className="filter-container">
                      <FilterComponent data={entity} type={"budget_scenario"} setFilterString={setFilterString} setShowFilterInput={setShowFilterInput}/>
                    </div>: null} 
                  </div>
                  <div className="list-sort">
                    {(!sortBudgetScenario)?null:<FontAwesomeIcon className="sort-icon" icon={(sortBudgetScenario == "asc")?faSortUp:faSortDown}/>}
                    {<FontAwesomeIcon className="filter-icon hover" onClick={()=>handleFilterClicked("budget_scenario")} icon={faFilter}/>}
                  </div>
                </div>
              </td>
              {(importType == "exactonline") ?<td className="list-fullname">
                <div className="list-flex">
                  <div className={(showFilterInput == "name")?"name-el no-wrap":"name-el no-wrap hover"}   onClick={(showFilterInput == "name")?null:()=>handleSortClicked("name")}>
                    {getTranslation("import_Name")}
                    {(showFilterInput == "name") ? <div className="filter-container">
                      <FilterComponent data={entity} type={"name"} setFilterString={setFilterString}  setShowFilterInput={setShowFilterInput}/>
                    </div>: null} 
                  </div>
                  <div className="list-sort">
                    {(!sortName)?null:<FontAwesomeIcon className="sort-icon" icon={(sortName == "asc")?faSortUp:faSortDown}/>}
                    {<FontAwesomeIcon className="filter-icon hover" onClick={()=>handleFilterClicked("name")} icon={faFilter}/>}
                  </div>
                </div>
              </td>:null}
              {/* <td className="list-created hover">
                <div className="created-el no-wrap hover" onClick={()=>handleSortClicked("created")}>
                {getTranslation("import_DateCreated")}
                </div>
                <div className="list-sort">
                  {(!sortCreated)?null:<FontAwesomeIcon className="sort-icon" onClick={(event)=>handleFilterClicked(event)} icon={(sortCreated == "asc")?faSortUp:faSortDown}/>}
                </div>
              </td> */}
              <td className="list-stamp no-wrap hover">
                <div className="stamp-el hover" onClick={()=>handleSortClicked("stamp")}>
                {getTranslation("import_LastUpdated")}
                </div>
                <div className="list-sort">
                  {(!sortStamp)?null:<FontAwesomeIcon className="sort-icon" onClick={(event)=>handleFilterClicked(event)} icon={(sortStamp == "asc")?faSortUp:faSortDown}/>}
                </div>
              </td>
              <td className="list-planned no-wrap hover">
                <div className="planned-el hover" onClick={()=>handleSortClicked("planned")}>
                {getTranslation("import_FirstPlanned")}
                </div>
                <div className="list-sort">
                  {(!sortStamp)?null:<FontAwesomeIcon className="sort-icon" onClick={(event)=>handleFilterClicked(event)} icon={(sortStamp == "asc")?faSortUp:faSortDown}/>}
                </div>
              </td>
              <td className="list-scheduler no-wrap">
                {getTranslation("import_Scheduler")}
              </td>
              <td className="list-update no-wrap">
                {getTranslation("import_Import")}
              </td>
              <td className="edit"></td>
            </tr>
          {entity.map((row, index) => (
            <tr key={index} value={index}>
              <td>{row["entiteitDesc"]}{/*Array.isArray(getCaseNameById(row["entiteit"])) && getCaseNameById(row["entiteit"]).length > 0 ? getCaseNameById(row["entiteit"].substring(0, row["entiteit"].indexOf('_'))) : ""}*/}</td> 
              {(importType == "exactonline") ?<td>{row["office_description"]}</td>:null}
              <td>{row["year"]}</td>
              <td>{(row["budget_scenario"] == "act") ? "Actuals" : row["budget_scenario"]}</td>
              {(importType == "exactonline") ? <td>{row["name"]}</td>:null}
              <td>{(row["stamp"])?new Date(row["stamp"]).toLocaleString(`${language.language_code}-${language.country_code}`, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }):"-"}</td>  
              <td>{(firstScheduledImports[row.id] && firstScheduledImports[row.id] != "-")?firstScheduledImports[row.id].toLocaleString(`${language.language_code}-${language.country_code}`, {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            }):"-"}</td>
              <td className="td-plan">
                 {showScheduler === index && (
                // <ScheduleComponent 
                //   importData = {data.filter(item => item.id == row["id"])[0]}
                //   onCancel={closeOptions}
                //   importType={importType}
                // /> 
                <div className="list-scheduler-container">
                <SchedulerComponent
                  importData = {data.filter(item => item.id == row["id"])[0]}
                  onCancel={closeOptions}
                  importType={importType}
                  reloadListComponent={()=>{reloadImport()}}
                  />
                </div>
              )}
              <button className="btn-list btn-import-schedule hover" onClick={() => {
                  scheduleImportClicked(index); 
                  }}>{getTranslation('import_PlanImport')}</button>  
                  
              </td>
              <td className="td-update">
                <button className="btn-list hover" onClick={() => {
                  handleImport(row, updateStatus); 
                  // clearStatus(); 
                  }}>{getTranslation('import_Update')}</button>
              </td>
             
              <td className="td-editor">
                <div className={`list-editor list-editor-${index}`} value={index} onClick={(event) => handleListEditor(event)}>
                  <FontAwesomeIcon className="editor-icon hover" icon={faEllipsisV}  />
                  <div className="editor-container">
                    <div className="editor-dropdown hidden">
                      <div className="editor-dropdown-container">
                        <div className="edit-list list-editor-option hover" onClick={() => listEditClicked(index)}>
                          <p>{getTranslation('import_Options')}</p>
                        </div>
                        <div className="delete-list list-editor-option hover" onClick={() => listDeleteClicked(index)}>
                          <p>{getTranslation('import_Delete')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>    
                {showConfirm === index && (
                <ConfirmationComponent
                  message="Weet u zeker dat u het geselecteerde import wilt veranderen?"
                  onCancel={closeOptions} 
                  onConfirm={() => {handleDelete(row["id"]);}}
                />
              )} 
              {showEdit === index && (
                <EditComponent
                  id = {row["id"]}
                  openingBalance={row["opening_balance"]}
                  ignoreYearEnd={row["ignore_year_end"]}
                  updateImport={(id, openingBalance, ignoreYearEnd) => 
                    updateImport(id, openingBalance, ignoreYearEnd)}
                  onCancel={closeOptions} 
                  reloadList={()=>{reloadImport()}}
                />
              )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>   
      <StatusComponent 
      data={statusUpdates} 
      currentProgress={(statusUpdates.length>0)?statusUpdates[statusUpdates.length-1]:{"progress": 0, "status": 0}}
      reloadList={()=>{reloadImport()}}
      />
    </div>
  );
};

export default ListComponent;