import React, { useState, useEffect, useRef } from 'react';
// import '../css/SchedulerComponent.css';
import { getCookie, } from '../utilities/utils'; // Import the utility function
import { clearExistingScheduledImports, } from '../utilities/import'; // Import the utility function
import CalendarComponent from './CalendarComponent';
import ConfirmationComponent from './ConfirmationComponent';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslations  } from './TranslationContext';
import Draggable from 'react-draggable';
// Import the core library and icon packs you need
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV, faSortDown, faSortUp, faFilter, faXmark, faRepeat, faClock, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Add the icons you will use to the library
library.add(faEllipsisV, faSortDown, faSortUp, faFilter, faXmark, faRepeat, faClock, faCheck);
function ScheduleComponent({importData, onCancel, importType, reloadListComponent}) {
  const { language, getTranslation } = useTranslations();
  const [scheduledImports, setScheduledImports] = useState([]);
  const [selectedScheduled, setSelectedScheduled] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedTime, setSelectedTime] = useState(false);
  const [lastAction, setLastAction] = useState(null);
  const lastClicked = useRef(null);
  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    if(selectedScheduled){
      document.querySelector(".btn-toggle-calendar").click();
      setSelectedScheduled(false);
    }
  }, [scheduledImports, selectedScheduled, showConfirm]); // Dependency array ensures this effect runs after selectedDates changes
  
  useEffect(()=>{
    fetchScheduledImports(importData);
  }, []);

  // async function handleScheduledImports(importData){
  //   const scheduledImports =  await fetchScheduledImports(importData); 
  //   var dates = [...selectedDates];
  //   for(const imp of scheduledImports){
  //     var scheduledTime = new Date(imp.scheduled_time);
  //     dates.push(scheduledTime);
  //   }
    
  //   if(dates.length > 0){
  //   } 
  // }
  async function reloadList(){
    fetchScheduledImports(importData);
    reloadListComponent();
  }
  async function fetchScheduledImports(importData){
    const response = await fetch('/scheduler/getScheduledImportBy', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        import_type: importData.import_type,
        entiteit: importData.entiteit,
        year: importData.year,
        office: importData.office,
        budget_scenario: importData.budget_scenario,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      await handleScheduledImports(responseData);
      return responseData;
    }
  }

  async function handleScheduledImports(scheduledImports){
    var activeImps = [];
    console.log(scheduledImports);
    for(var i = 0; i<scheduledImports.length; i++){
      if(new Date(scheduledImports[i].scheduled_time) > new Date(new Date() - 24 * 60 * 60 * 1000)){
        activeImps.push(scheduledImports[i]);
      }
    }
    setScheduledImports(activeImps);
  }

  
  // async function submitClicked(){
  //   await clearExistingScheduledImports({"import_type": importType, "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]})
  //   var [time, period] = selectedTime.split(" ");
  //   var [hours, minutes] = time.split(":");
  //   hours = (period == "AM" && hours != 12) ? parseInt(hours) : parseInt(hours) + 12;
  //   var scheduledImports = [];

  //   for(const date of selectedDates){
  //     date.setHours(hours);
  //     date.setMinutes(parseInt(minutes));
  //     await postScheduledImport({"import_type": importType, "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"], "scheduled_time": date});
  //   }
  //   window.location.href = window.location.href;
  // }

  // Posts scheduled import based on the calendar inputs
  async function postScheduledImport(body){
    try{
      const response = await fetch('/scheduler/createScheduledImport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (response.ok) {
        const responseData = await response.json();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function editClicked(scheduledImport){
    console.log(scheduledImport);
    setSelectedScheduled(scheduledImport);
    // document.querySelector(".btn-toggle-calendar").click();
  }

  async function deleteClicked(index){
    setShowConfirm(index);
  }
  async function deleteScheduledImport(id){
    try{
      const response = await fetch('/scheduler/deleteScheduledImportById?id='+id);
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData);
        reloadList();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  async function toggleEditDropdown(index){
    var editContainer = document.querySelector(".scheduler-edit-"+index);
    var editEl = editContainer.parentNode.querySelector(".edit-scheduler-container");
    if(editEl.classList.contains("hidden")){
      editEl.parentNode.querySelector(".edit-scheduler-container").classList.remove("hidden");
    }
    else{
      editEl.parentNode.querySelector(".edit-scheduler-container").classList.add("hidden");
    }

    function handleClickOutside(event) {
      // Check if the clicked element is not the editor button or a descendant of it
      if (editContainer && !editContainer.contains(event.target)) {
          // Close the editor
          editEl.parentNode.querySelector(".edit-scheduler-container").classList.add("hidden");

          // Remove the click event listener after closing the editor
          document.removeEventListener('click', handleClickOutside);
      }
    }

    // Add the click event listener to the document
    document.addEventListener('click', handleClickOutside);
  }
  // Close all list editors
  async function closeAllListEditors(){
    var editors = document.querySelector(".scheduler-component").querySelectorAll(".edit-scheduler-container");
    for(const editor of editors){
      if(!editor.classList.contains("hidden")){
        editor.classList.add("hidden");
      }
    }
  } 

  return (
    <Draggable>
    <div className="scheduler-component">
      <div className="scheduler-header">
        <div className={`scheduler-close-window hover`} onClick={onCancel}><FontAwesomeIcon className="close-icon" icon={faXmark}/></div>
        <p>Import planner</p>
      </div>
      <div className="scheduler-content">
        <table className="scheduler-table" border="1">
          <tbody> 
            <tr className="scheduler-tr">
              <td className="scheduler-td">
                <p>Scheduled imports</p>
              </td>
              <td>
              </td>
            </tr>
            {scheduledImports.map((row, index) => (
            <tr className={`scheduler-tr-${index}`} key={index} value={index}>
              <td className="scheduler-td" key={index} value={index}>
                <div className="scheduled-wrapper">
                  <div className="scheduled-wrapper-header">
                    <p>
                    {new Date(row["scheduled_time"]).toLocaleString(`${language.language_code}-${language.country_code}`, {
                        weekday: 'long', 
                     })
                     }
                     {(row["status"] == 0) ? <FontAwesomeIcon className="scheduled-status" icon={faCheck}/> :
                     (new Date(row["scheduled_time"]) > new Date()) ?
                     (row["status"] == 2) ? <FontAwesomeIcon className="scheduled-status" icon={faRepeat}/>
                     : <FontAwesomeIcon className="scheduled-status" icon={faClock}/> : <FontAwesomeIcon className="scheduled-status" icon={faXmark}/>}
                    </p>
                  </div>
                  <div className="scheduled-wrapper-date">
                      <p>{new Date(row["scheduled_time"]).toLocaleString(`${language.language_code}-${language.country_code}`, {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric'
                     })}</p>
                  </div>
                </div>
              </td>
              <td className="edit-scheduler-td"> 
                <div className="edit-scheduler-container hidden" >
                  <div className="edit-scheduler">
                    <div className="scheduler-edit hover" onClick={() => editClicked(row)}>
                      <p>Edit</p>
                    </div>
                    <div className="scheduler-delete hover" onClick={()=> deleteClicked(index)}>
                        <p>{getTranslation('import_Delete')}</p>
                    </div>
                  </div>
                </div>
                <div className={`scheduler-edit-${index} scheduler-edit-btn`} onClick={() => toggleEditDropdown(index)}>
                  <FontAwesomeIcon className="editor-icon hover" icon={faEllipsisV}/>
                </div>
              </td> 
              {showConfirm === index && ( 
              <ConfirmationComponent
                message="Weet u zeker dat u het geselecteerde gescheduled import wilt verwijderen?"
                onCancel={() => { setShowConfirm(false); }} 
                onConfirm={() => {deleteScheduledImport(row["id"]);}}
              /> )}
            </tr>
           
            ))}
          </tbody>
        </table>
        {/* <div className="datetime-el"></div> */}
        <CalendarComponent
          importData={importData}
          importType={importType}
          scheduledImport={selectedScheduled}
          reloadList={reloadList}
        />
        
      </div>
     
    </div>      
    </Draggable>
  );
}

export default ScheduleComponent;