import React, { useState, useEffect, useRef } from 'react';
import MCDatepicker from '../utilities/MCDatepicker/src/js/mc-calendar';
import { getCookie, } from '../utilities/utils'; 
import '../css/SchedulerComponent.css';
function CalendarComponent({importData, importType, scheduledImport, reloadList}) {
  const [datepicker, setDatepicker] = useState(false);
  // useEffect to execute side effect after selectedDates update
  useEffect(() => {
    console.log(scheduledImport);
  }, [datepicker]); 

  async function createCalendar(){
    console.log(importData);
    var datePickerOptions = {
      el: '#datepicker-el-'+importData["id"],
      bodyType: 'inline',
      closeOnBlur: true,
      dateFormat: 'dddd, dd mmmm yyyy',
      customWeekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      customMonths: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
      ],
      customOkBTN: 'OK',
      customClearBTN: 'Clear',
      customCancelBTN: 'Cancel',
      firstWeekday: 1,
      minDate: new Date(),
      maxDate: new Date(getCookie("exact_refresh_expiration")),
      importData:{'entiteit': importData.entiteit, "year": importData.year, 'budget_scenario': importData.budget_scenario, 'office': importData.office, 'import_type': importType},
      reloadList: reloadList,
    }
    if(scheduledImport){
      console.log(new Date(scheduledImport.scheduled_time));
      datePickerOptions.scheduled_id = scheduledImport.id;
      datePickerOptions.selectedDate = new Date(scheduledImport.scheduled_time);
      datePickerOptions.scheduled_type = scheduledImport.status;
      if(scheduledImport.status == 2){
        datePickerOptions.selectedWeekday = new Date(scheduledImport.scheduled_time).getDay();
      }
    }
    
    setDatepicker(MCDatepicker.create(datePickerOptions));
  }

  async function toggleCalendar(){
    await createCalendar();
    var datePickerEl = document.querySelector("#datepicker-el-"+importData["id"]);
    datePickerEl.focus();
  }
  return (
    <div className="calendar-component">
      <input id={`datepicker-el-${importData["id"]}`} className="datepicker-el"/>
      <button className="btn btn-toggle-calendar" onClick={toggleCalendar}>Schedule new</button>
    </div>
  );
}

export default CalendarComponent;