import { checkImportExact, clearExistingScheduledImports} from './import';

// Handles exactonline import execution on the server
export async function handleImport(row, updateStatus){
  // await checkImportExact(data[id].entiteit, data[id].year, data[id].budget_scenario, office);
  try {
    handleStatus(updateStatus);
    const response = await fetch('/exactonline/executeImport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json' 
      },
      body: JSON.stringify({
        entiteit: row.entiteit,
        year: row.year,
        budget_scenario: row.budget_scenario,
        office: row.office,
      }),
    });
    if (response.ok) {
      // const responseData = await response.json();  
      // return responseData;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    handleStatus({"text": "Error!", "progress": 1, "status": 0});
  }
}

//Submits import exact updates/changes
export async function updateImportExact(id, openingBalance, ignoreYearEnd){
  try{
    const response = await fetch('/import_exact/updateImportExact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: id,
        openingBalance: openingBalance,
        ignoreYearEnd: ignoreYearEnd,
      }),
    })
    if (response.ok) {
      const responseData = await response.json();
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

// Delete import exact by id
export async function deleteImportExact(id){
  try { 
    console.log(id);
    // var importData = data.filter(item => item.id == id)[0];
    const importResponse = await fetch('/import_exact/getById?id='+id);
    if(importResponse.ok){
      const importData = importResponse.json();

      await clearExistingScheduledImports({"import_type": "exactonline", "entiteit": importData["entiteit"], "year": importData["year"],"office": importData["office"], "budget_scenario": importData["budget_scenario"]})
    
      const response = await fetch('/import_exact/deleteImportExact?id='+id);
      if (response.ok) {
      const responseData = await response.json();
      
      if(responseData){
      }
    }
    
  }
  } catch (error) {
    console.error('Error deleting data:', error);
  }
}

// Handles fetching status updates from the server
async function handleStatus(updateStatus){
  const response = await fetch('/exactonline/fetchUpdates');

  if (response.ok) {
    const responseData = await response.json();
    
    if(responseData){
      updateStatus(responseData);

      if(responseData.length > 0 && responseData[responseData.length-1]["status"] == 1){
        setTimeout(function(){
          handleStatus(updateStatus);
        },100);
      }
    } 
  }
}


