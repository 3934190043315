import { spanTemplate } from './template';
import { Animation } from './utils';
import {
	CALENDAR_HIDE,
	CALENDAR_SHOW,
	CALENDAR_UPDATE,
	DISPLAY_UPDATE,
	PREVIEW_UPDATE,
	HEADER_UPDATE,
	CHANGE_MONTH,
	CHANGE_YEAR,
	DATE_PICK,
	PREVIEW_PICK,
	SET_DATE,
	CANCEL
} from './events';
import {
	dispatchDatePick,
	dispatchChangeMonth,
	dispatchChangeYear,
	dispatchPreviewCellPick,
	dispatchCancel
} from './emiters';

import {
	updateCalendarTable,
	updateCalendarHeader,
	updateMonthYearPreview,
	updateCalendarUI,
	updateDisplay,
	updatePickedDateValue,
	updateLinkedInputValue,
	updateMonthSelect,
	updateYearSelect,
	getTargetDate,
	getNewMonth,
	getNewYear,
} from './handlers';

export const applyListeners = (calendarNodes) => {
	let activeInstance = null;
	let clickable = true;
	const {
		calendarStates,
		calendar,
		calendarDisplay,
		calendarPicker,
		calendarHeader,
		currentMonthSelect,
		currentYearSelect,
		monthYearPreview,
		monthNavPrev,
		monthNavNext,
		yearNavPrev,
		yearNavNext,
		dateCells,
		previewCells,
		cancelButton,
		okButton,
		clearButton,
		weeklyButton, 
		weekdayButtons,
		timeOptions,
	} = calendarNodes;

	calendar.addEventListener(CALENDAR_SHOW, (e) => {
		activeInstance = e.detail.instance;
		// update the calendar UI
		updateCalendarUI(calendarNodes, activeInstance);
		// show the calendar
		calendar.classList.add('mc-calendar--opened');

		// calendar.focus();
		// run all custom onOpen callbacks added by the user
		activeInstance.onOpenCallbacks.forEach((callback) => callback.apply(null));
	});

	calendar.addEventListener(CALENDAR_HIDE, () => {
		// calendar.removeEventListener(CALENDAR_SHOW, showFunction);
		const { options, onCloseCallbacks } = activeInstance;
		// hide the calendar
		calendar.classList.remove('mc-calendar--opened');
		// delete the style attribute for inline calendar
		if (options.bodyType == 'inline') calendar.removeAttribute('style');
		// wait for animation to end and remove the --opened class
		// getAnimations(calendar).then(() => {
		// 	store.preview.setTarget = 'calendar';
		// });
		// reset the active instance
		activeInstance = null;
		// run all custom onClose callbacks added by the user
		onCloseCallbacks.forEach((callback) => callback.apply(null));
	});
	calendar.addEventListener(DATE_PICK, (e) => {
		if (!activeInstance) return;
		const { options } = activeInstance;
		const { autoClose, closeOndblclick } = options;
		if (e.target.classList.contains('mc-date--inactive')) return;

		if (e.detail.dblclick) {
			if (!closeOndblclick) return;
			return updatePickedDateValue(activeInstance, calendarStates);
		}

		var deselect = e.target.classList.contains('mc-date--picked');
		
		// update the instance picked date
		// activeInstance.pickedDate.add(e.detail.date);
		// update display store data
		activeInstance.store.display.setDate = e.detail.date;


		// update the classlist of the picked cell
		// dateCells.forEach((cell) => cell.classList.remove('mc-date--picked'));
		
		// if (e.target.classList.contains('mc-date--picked')) {
		// 	e.target.classList.remove('mc-date--picked');
		// 	activeInstance.pickedDate.remove(e.detail.date);
		// }else{
		// 	e.target.classList.add('mc-date--picked');
		// 	// update the instance picked date
		// 	if (Array.isArray(activeInstance.pickedDate)) {
		// 		activeInstance.pickedDate.push(e.detail.date);  // Add the new date to the array
		// 	} else {
		// 		activeInstance.pickedDate = [e.detail.date];  // Initialize pickedDate as an array with the new date
		// 	}
		// }	
		if(!deselect){
			if(!e.target.classList.contains('mc-date--picked')){
				e.target.classList.add('mc-date--picked');
			}
			activeInstance.pickedDateArray.push(e.detail.date);
			activeInstance.pickedDate = e.detail.date;
			console.log(activeInstance);
			filterTimeOptions();
			if (autoClose) updatePickedDateValue(activeInstance, calendarStates);
		}
		else{
			handleRemoveSelectedDate(activeInstance, e.detail.date);
			activeInstance.pickedDate = null;
			console.log(activeInstance);
			e.target.classList.remove('mc-date--picked');
		}
	});

	calendar.addEventListener(PREVIEW_PICK, (e) => {
		if (!activeInstance) return;
		const { data, dblclick } = e.detail;
		const { store, options, viewLayers } = activeInstance;
		const { customMonths, autoClose, closeOndblclick } = options;
		const { target } = store.preview;

		if (e.target.classList.contains('mc-month-year__cell--inactive')) return;

		previewCells.forEach((cell) => cell.classList.remove('mc-month-year__cell--picked'));
		e.target.classList.add('mc-month-year__cell--picked');

		if (dblclick && store.preview.target === viewLayers[0]) {
			if (!closeOndblclick) return;
			return updatePickedDateValue(activeInstance, calendarStates);
		}

		let targetYear = store.preview.year;
		let targetMonth = customMonths[store.header.month];

		if (viewLayers[0] === 'year') targetMonth = customMonths[0];
		if (target === 'month') targetMonth = data;
		if (target === 'year') targetYear = Number(data);

		const targetMonthIndex = customMonths.findIndex((month) => month.includes(targetMonth));
		const nextCalendarDate = getTargetDate(activeInstance, new Date(targetYear, targetMonthIndex));

		store.header.month = nextCalendarDate.getMonth();
		store.preview.year = nextCalendarDate.getFullYear();
		if (viewLayers[0] !== 'year') store.header.year = nextCalendarDate.getFullYear();
		store.preview.month = nextCalendarDate.getMonth();

		if (viewLayers[0] !== 'calendar') activeInstance.pickedDate = nextCalendarDate;
		if (viewLayers[0] !== 'calendar') store.display.setDate = nextCalendarDate;
		if (viewLayers[0] === 'calendar') store.calendar.setDate = nextCalendarDate;

		if (autoClose && store.preview.target === viewLayers[0]) {
			return updatePickedDateValue(activeInstance, calendarStates);
		}
		store.preview.setTarget = viewLayers[0];
		store.header.setTarget = viewLayers[0];

		currentMonthSelect.setAttribute('aria-expanded', false);
		currentYearSelect.setAttribute('aria-expanded', false);

		// Return focus to correct location
		if (target == 'month') currentMonthSelect.focus();
		if (target == 'year') currentYearSelect.focus();
	});

	calendar.addEventListener(SET_DATE, (e) => {
		const { instance, date } = e.detail;
		instance.pickedDate = date;
		updateLinkedInputValue(instance);
		if (activeInstance?._id !== instance._id) return;
		const { store } = activeInstance;
		store.display.setDate = date;
		store.calendar.setDate = store.calendar.date;
		if (store.preview.target !== 'calendar') {
			store.preview.month = date.getMonth();
			store.preview.year = date.getFullYear();
			store.preview.setTarget = store.preview.target;
		}
		if (store.header.target === 'month') {
			store.header.month = date.getMonth();
			store.header.year = date.getFullYear();
			store.header.setTarget = store.header.target;
		}
	});

	calendar.addEventListener(
		CALENDAR_UPDATE,
		(e) => activeInstance && updateCalendarTable(calendarNodes, activeInstance)
	);

	document.addEventListener('click', (e) => {
		const targetElement = e.target;
		const isTargetCalendar = calendar.contains(targetElement);
		const isTargetInput = activeInstance?.linkedElement === targetElement;
		if (!isTargetCalendar && !isTargetInput && activeInstance) calendarStates.blur();
	});

	calendar.addEventListener(CANCEL, (e) => {
		if (!activeInstance) return;
		const { onCancelCallbacks } = activeInstance;
		onCancelCallbacks.forEach((callback) => callback.apply(null));
		calendarStates.close();
	});

	calendarDisplay.addEventListener(DISPLAY_UPDATE, (e) => {
		activeInstance && updateDisplay(calendarNodes, activeInstance);
	});

	calendarHeader.addEventListener(
		HEADER_UPDATE,
		(e) => activeInstance && updateCalendarHeader(calendarNodes, activeInstance)
	);

	// monthYearPreview.addEventListener(
	// 	PREVIEW_UPDATE,
	// 	(e) => activeInstance && updateMonthYearPreview(calendarNodes, activeInstance)
	// );

	currentMonthSelect.addEventListener(CHANGE_MONTH, function (e) {
		// check if the button is clickable
		if (!clickable || !activeInstance) return;
		clickable = !clickable;
		const slider = Animation();
		const {
			store,
			viewLayers,
			options,
			onMonthChangeCallbacks,
			onYearChangeCallbacks
		} = activeInstance;
		const { customMonths } = options;
		const { direction } = e.detail;
		// get the value of active month
		const selectedMonth = customMonths[store.header.month];
		// get the value of active Year
		let selectedYear = store.header.year;
		// get the next ot prev month and the overlap value
		const { newMonth, overlap } = getNewMonth(activeInstance, selectedMonth, direction);
		const newYear = overlap !== 0 ? getNewYear(options, selectedYear, direction) : selectedYear;
		const newCalendarDate = new Date(newYear, newMonth.index, 1);
		// add a new span tah with the new month to the months div
		if (overlap !== 0) {
			// add a new span with the new year to the years div
			currentYearSelect.innerHTML += spanTemplate(direction, newYear);
			// apply slide animation to years span tags
			slider.slide(currentYearSelect.children[0], currentYearSelect.children[1], direction);
			onYearChangeCallbacks.forEach((callback) => callback.apply(null));
		}

		e.target.innerHTML += spanTemplate(direction, newMonth.name);
		// apply slide animation to months span tags
		slider.slide(e.target.children[0], e.target.children[1], direction);

		slider.onFinish(() => {
			// update the calendar table
			if (viewLayers[0] === 'calendar') store.calendar.setDate = newCalendarDate;
			if (viewLayers[0] !== 'calendar') store.display.setDate = newCalendarDate;
			if (viewLayers[0] === 'month') activeInstance.pickedDate = newCalendarDate;
			store.header.year = newCalendarDate.getFullYear();
			store.header.setMonth = newCalendarDate.getMonth();
			store.preview.year = newCalendarDate.getFullYear();
			store.preview.setMonth = newCalendarDate.getMonth();
			// run all custom onMonthChangeCallbacks added by the user
			onMonthChangeCallbacks.forEach((callback) => callback.apply(null));

			clickable = !clickable;
		});
	});

	currentYearSelect.addEventListener(CHANGE_YEAR, function (e) {
		if (!clickable || !activeInstance) return;
		clickable = !clickable;
		const { direction } = e.detail;
		const {
			store,
			viewLayers,
			options,
			onMonthChangeCallbacks,
			onYearChangeCallbacks,
			prevLimitDate,
			nextLimitDate
		} = activeInstance;
		const { customMonths } = options;
		const slider = Animation();
		const next = direction === 'next' ? true : false;
		const selectedYear = store.header.year;
		const currentMonthIndex = store.header.month;
		const viewTarget = store.header.target;
		const newYear = getNewYear(options, selectedYear, direction);

		let newMonth = null;
		let newCalendarDate =
			newYear && getTargetDate(activeInstance, new Date(newYear, currentMonthIndex, 1));
		if (!newYear) newCalendarDate = next ? nextLimitDate : prevLimitDate;
		if (newCalendarDate.getMonth() !== currentMonthIndex)
			newMonth = customMonths[newCalendarDate.getMonth()];

		if (viewTarget === 'year') {
			const firstTableYear = store.header.year;
			const targetYear = next ? firstTableYear + 12 : firstTableYear - 12;
			store.header.setYear = targetYear;
			store.preview.setTarget = 'year';
			clickable = !clickable;
			return;
		}

		if (newMonth) {
			currentMonthSelect.innerHTML += spanTemplate(direction, newMonth);
			slider.slide(currentMonthSelect.children[0], currentMonthSelect.children[1], direction);
			onMonthChangeCallbacks.forEach((callback) => callback.apply(null));
		}
		if (newYear) {
			e.target.innerHTML += spanTemplate(direction, newYear);
			slider.slide(e.target.children[0], e.target.children[1], direction);
			onYearChangeCallbacks.forEach((callback) => callback.apply(null));
		}
		slider.onFinish(() => {
			if (viewLayers[0] === 'calendar') store.calendar.setDate = newCalendarDate;
			if (viewLayers[0] !== 'calendar') store.display.setDate = newCalendarDate;
			if (viewLayers[0] !== 'calendar') activeInstance.pickedDate = newCalendarDate;
			store.preview.year = newCalendarDate.getFullYear();
			store.preview.setMonth = newCalendarDate.getMonth();
			store.header.year = newCalendarDate.getFullYear();
			store.header.setMonth = newCalendarDate.getMonth();
			clickable = !clickable;
		});
	});

	currentMonthSelect.addEventListener(
		'click',
		() => activeInstance && updateMonthSelect(activeInstance, calendarNodes)
	);

	// currentYearSelect.addEventListener('keydown', (e) => {
	// 	if (e.key == 'Enter') updateMonthSelect(activeInstance, calendarNodes, 'keyboard');
	// 	if (e.key == 'Tab' && !e.shiftKey) {
	// 		// Correct focus order
	// 		e.preventDefault();
	// 		currentYearSelect.focus();
	// 	}
	// });

	// currentYearSelect.addEventListener(
	// 	'click',
	// 	() => activeInstance && updateYearSelect(activeInstance, calendarNodes)
	// );

	currentYearSelect.addEventListener('keydown', (e) => {
		if (e.key == 'Enter') updateYearSelect(activeInstance, calendarNodes, 'keyboard');
		if (e.key == 'Tab') {
			// Correct focus order
			e.preventDefault();
			if (e.shiftKey) return currentMonthSelect.focus();
			monthNavNext.focus();
		}
	});

	// Dispatch custom events
	previewCells.forEach((cell) => {
		cell.addEventListener(
			'click',
			(e) => e.detail === 1 && dispatchPreviewCellPick(e.currentTarget)
		);
		cell.addEventListener(
			'dblclick',
			(e) => e.detail === 2 && dispatchPreviewCellPick(e.currentTarget, true)
		);
		cell.addEventListener(
			'keydown',
			(e) => e.key === 'Enter' && dispatchPreviewCellPick(e.currentTarget)
		);
	});

	// add click event that dispatch a custom DATE_PICK event, to every calendar cell
	dateCells.forEach((cell) => {
		cell.addEventListener('click', (e) => e.detail === 1 && dispatchDatePick(e.target));
		cell.addEventListener('dblclick', (e) => e.detail === 2 && dispatchDatePick(e.target, true));
		cell.addEventListener('keydown', (e) => {
			e.key === 'Enter' && dispatchDatePick(e.target);
			e.key === 'End' && clearButton.focus();
		});
	});

	monthNavPrev.addEventListener('click', (e) => {
		if (e.currentTarget.classList.contains('mc-select__nav--inactive')) return;
		dispatchChangeMonth(currentMonthSelect, 'prev');
	});

	monthNavNext.addEventListener('click', (e) => {
		if (e.currentTarget.classList.contains('mc-select__nav--inactive')) return;
		dispatchChangeMonth(currentMonthSelect, 'next');
	});
	monthNavNext.addEventListener('keydown', (e) => {
		// correct focus order
		if (e.key == 'Tab') {
			e.preventDefault();
			if (e.shiftKey) return currentYearSelect.focus();
			calendarHeader.nextElementSibling.querySelector('[tabindex="0"]').focus();
		}
	});

	yearNavPrev.addEventListener('click', (e) => {
		if (e.currentTarget.classList.contains('mc-select__nav--inactive')) return;
		dispatchChangeYear(currentYearSelect, 'prev');
	});

	yearNavNext.addEventListener('click', (e) => {
		if (e.currentTarget.classList.contains('mc-select__nav--inactive')) return;
		dispatchChangeYear(currentYearSelect, 'next');
	});

	cancelButton.addEventListener('click', (e) => dispatchCancel(calendar));

	calendarPicker.addEventListener('keyup', (e) => e.key == 'Escape' && dispatchCancel(calendar));

	okButton.addEventListener('click',
		(e) => activeInstance && submitClicked(activeInstance, calendarStates)
	);

	timeOptions.forEach((option)=>{
		option.addEventListener('click', (e) => {
			if(!option.classList.contains("time-select-option-disabled")){
				timeOptions.forEach((option)=>option.classList.remove("time-selected"));
				option.classList.add("time-selected");
				var selectedTime = option.getAttribute("value");
				activeInstance.pickedTime = Number(selectedTime);
				console.log(activeInstance);
			}
		});
	});
	filterTimeOptions();
	clearButton.addEventListener('click', (e) => {
		if (!activeInstance) return;
		const { linkedElement, onClearCallbacks } = activeInstance;
		dateCells.forEach((cell) => cell.classList.remove('mc-date--picked'));
		activeInstance.pickedDate = null;
		if (linkedElement) linkedElement.value = null;
		onClearCallbacks.forEach((callback) => callback.apply(null));
	});

	weeklyButton.addEventListener('click', (e) => {
		var calendarPicker = document.querySelector(".mc-picker");
		var calendarHeader = calendarPicker.querySelector(".mc-picker__header");
		var tableBody = calendarPicker.querySelector(".mc-table__body");
		console.log(tableBody.classList);
		if(!tableBody.classList.contains("hidden")){
			calendarPicker.querySelector(".mc-table__body").classList.add("hidden");
			calendarPicker.querySelectorAll(".mc-table__weekday").forEach(function(weekday){
				weekday.classList.add("hover");
			});
			calendarHeader.querySelector(".mc-picker__weekday").classList.remove("hidden");
			calendarHeader.querySelector(".mc-select__month").classList.add("hidden");
			calendarHeader.querySelector(".mc-select__year").classList.add("hidden");
		}
		else{
			calendarPicker.querySelector(".mc-table__body").classList.remove("hidden");
			calendarHeader.querySelector(".mc-picker__weekday").classList.add("hidden");
			calendarHeader.querySelector(".mc-select__month").classList.remove("hidden");
			calendarHeader.querySelector(".mc-select__year").classList.remove("hidden");
			calendarPicker.querySelectorAll(".mc-table__weekday").forEach(function(weekday){
				weekday.classList.remove("hover");
			});
		}
		
	});
	weekdayButtons.forEach(function(weekday){
		weekday.addEventListener('click', (e) => {
			if(weekday.classList.contains("weekday-selected")){
				weekday.classList.remove("weekday-selected");
			}
			else{
				weekday.classList.add("weekday-selected");
				var targetWeekday = weekday.getAttribute("value");
				getNextWeekday(targetWeekday);
			}
		});
	});
};

export const applyOnFocusListener = (instance) => {
	if (!instance.linkedElement) return;
	instance.linkedElement.onfocus = (e) => {
		instance.open();
	};
};

export const removeOnFocusListener = ({ linkedElement }) => {
	if (linkedElement) linkedElement.onfocus = null;
};

async function filterTimeOptions(){
	var disabledTimeArray = [4, 4.5, 5, 5.5, 6, 6.5];
	
	var selectedDateEl = document.querySelector(".mc-date--picked");
	if(selectedDateEl && selectedDateEl.classList.contains("mc-date--today")){
		var now = new Date();
		var timePassed = now.getHours();
		timePassed += (now.getMinutes() >= 30)?0.5:0;
		for(var i = 0; i <= 23.5; i+=0.5){
			if(i<=timePassed && !disabledTimeArray.includes(i)){
				disabledTimeArray.push(i);
			}
		}
	}
	var disabledTimeElements = document.querySelectorAll(".time-select-option-disabled");
	disabledTimeElements.forEach(function (disabledTime){
		disabledTime.classList.add("time-select-option");
		disabledTime.classList.remove("time-select-option-disabled");
		
	});
	var timeSelectElements = document.querySelectorAll(".time-select-option");
	timeSelectElements.forEach(function (timeEl){
		disabledTimeArray.forEach(function(time){
			if(timeEl.getAttribute("value") == time){
				timeEl.classList.add("time-select-option-disabled");
				timeEl.classList.remove("time-select-option");
			}
		});
	});
	
}
async function submitClicked (activeInstance, calendarStates){
	const { pickedDate, linkedElement, onSelectCallbacks, options } = activeInstance;
	var newScheduledTime = new Date(activeInstance.pickedDate);
	console.log(activeInstance);
	console.log(newScheduledTime);
	var hour = Math.floor(activeInstance.pickedTime);
	var minutes = (activeInstance.pickedTime - hour) * 60;
	newScheduledTime.setHours(hour);
	newScheduledTime.setMinutes(minutes);
	
	if((newScheduledTime > new Date() || document.querySelector(".weekday-selected")) && activeInstance.pickedDateArray.length > 0){
		var importData = activeInstance.options.importData;
		await handlePostWeekly(importData, hour, minutes);
		await handlePostDate(activeInstance, hour, minutes);
		
		await activeInstance.options.reloadList();
		calendarStates.close();
	}
	else{
		alert("Ongeldige invoer, zorg dat je een datum en tijd heb geselecteerd.");
	}
}

async function deleteExistingScheduledImport(id){
	try{
		const response = await fetch('/scheduler/deleteScheduledImportById?id='+id);
		if (response.ok) {
		  const responseData = await response.json();
		  console.log(responseData);
		}
	} catch (error) {
		console.error('Error fetching data:', error);
	}
}

async function getScheduledImport(body){
	try{
		const response = await fetch('/scheduler/getScheduledImport', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(body),
		})
		if (response.ok) {
		  const responseData = await response.json();
		  console.log(responseData);
		  return responseData;
		}
	} catch (error) {
	console.error('Error fetching data:', error);
	}
}

async function postScheduledImport(body){
	try{
		const response = await fetch('/scheduler/createScheduledImport', {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  body: JSON.stringify(body),
		})
		if (response.ok) {
		  const responseData = await response.json();
		}
	} catch (error) {
	console.error('Error fetching data:', error);
	}
}
async function handleRemoveSelectedDate(activeInstance, targetDate){
	var pickedDateArray = activeInstance.pickedDateArray;
	for(var i = 0; i < pickedDateArray.length; i++){
		if(pickedDateArray[i].getMonth() == targetDate.getMonth() && pickedDateArray[i].getFullYear() == targetDate.getFullYear() && pickedDateArray[i].getDate() == targetDate.getDate()){
			pickedDateArray.splice(i, 1);
		}
	}
	activeInstance.pickedDateArray = pickedDateArray;
	return activeInstance;
}
async function getNextWeekday(targetWeekday){
	const today = new Date();
    const currentWeekday = today.getDay(); // 0 = Sunday, 6 = Saturday
    let daysAhead = (targetWeekday - currentWeekday + 7) % 7;

    // Calculate the next occurrence
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysAhead);
	nextDate.setSeconds(0);
	nextDate.setMilliseconds(0);
    return nextDate;
}

async function handlePostDate(activeInstance, hour, minutes){
	var pickedDateArray = activeInstance.pickedDateArray;
	var deleteId = activeInstance.options.scheduled_id; await deleteExistingScheduledImport(activeInstance.options.scheduled_id);
	for(var i = 0; i < pickedDateArray.length; i++){
		var date = new Date(pickedDateArray[i]);
		date.setHours(hour);
		date.setMinutes(minutes);
		if(date > new Date()){
			var scheduledImport = activeInstance.options.importData;
			scheduledImport.scheduled_time = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${(date.getMinutes() == 0)?"00":date.getMinutes() }:00`;
			scheduledImport.status = 1;
			var existsArray = await getScheduledImport(scheduledImport);
			if(existsArray && existsArray.length > 0){
				if(activeInstance.options.scheduled_id && existsArray[0] == activeInstance.options.scheduled_id){
					deleteId = null;
				}
			}
			else{
				await postScheduledImport(scheduledImport);	
			}
		}
	}
	if(deleteId){
		await deleteExistingScheduledImport(deleteId);
	}
}

async function handlePostWeekly(importData, hour, minutes){
	var selectedElements = document.querySelectorAll(".weekday-selected");
	console.log(selectedElements);
	for(var i = 0; i < selectedElements.length; i++){
		var scheduledImport = importData;
		var date = await getNextWeekday(selectedElements[i].getAttribute("value"));
		console.log(date);
		date.setHours(hour);
		date.setMinutes(minutes);
		scheduledImport.scheduled_time = date;
		scheduledImport.status = 2;
		await postScheduledImport(scheduledImport);
		selectedElements[i].classList.remove("weekday-selected");
	};
}